import { useFormik } from 'formik'
import * as Yup from 'yup'
import 'yup-personnummer'
import Personnummer from 'personnummer'
import to from 'await-to-fetch'
import { Button, Input, Icon, Loader } from '@aider/ui'
import { motion, AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import ReactGA from 'react-ga4'
import QRCode from "react-qr-code";
import { useState } from "react";

import { track, events } from '@utils/analytics'
import { isLoggedInVar } from '@/cache'

import authWithBankId from './authWithBankId'


const validationSchema = Yup.object().shape({
  pin: Yup.string()
    .required('Fyll i ett giltigt personnummer.')
    .personnummer('Fyll i ett giltigt personnummer'),
})

const Login = () => {
  const [qrCodeData, setQrCodeData] = useState("");
  const [autoStartToken, setAutoStartToken] = useState("");

  const history = useHistory()
  // Impersonate
  const params = new URLSearchParams(document.location.search)
  const impersonateToken = params.get('impersonateToken')
  console.log(impersonateToken)
  if (impersonateToken) {
    // We have an impersonateToken, lets add it to local storage to act as the user
    localStorage.setItem('accessToken', impersonateToken)
    window.location.reload()
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    isValid,
  } = useFormik({
    initialErrors: false,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: { pin: '' },
    onSubmit: async ({ pin }) => {
      const [error, response] = await to(
        authWithBankId({ pin: Personnummer.parse(pin).format('long') }, false, setQrCodeData, setAutoStartToken),
      )

      if (error) {
        return toast.error(
          'Kunde inte logga in. Kontrollera uppgifterna och försök igen.',
        )
      }

      const { token: { plainTextToken } = {}, expires_at: expires } = response

      // Create a timestamp from expires_at and convert it to seconds
      const expiresAt = new Date(expires).getTime() / 1000
      localStorage.setItem('accessToken', plainTextToken)
      localStorage.setItem('expiresAt', expiresAt)

      ReactGA.event('login')
      track(events.USER_LOGGED_IN)
      return isLoggedInVar(true)
    },
  })

  // Patch handleBlur to autocorrect personnummer
  const _handleBlur = event => {
    const { value } = event.target

    if (Personnummer.valid(value)) {
      setFieldValue('pin', Personnummer.parse(value).format())
    }

    handleBlur(event)
  }

  return (
    <motion.div
      key="login"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.2 }}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {isSubmitting ? (
          <motion.div
            key="loading"
            className="inset-x-0 flex flex-col items-center mx-auto p-4 w-full text-center bg-white border border-gray-300 rounded-xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
          >
            <Icon name="bank-id" className="mb-4 w-10 h-10" />
            <span className="block mb-2 text-black font-semibold">
              Öppna BankID
            </span>
            <span className="mb-8 max-w-xs text-gray-800">
              Starta BankID-appen på din mobil eller surfplatta och tryck på &quot;Skanna QR-kod&quot;.
            </span>
            <div className="mb-4">
              <QRCode value={qrCodeData} />
            </div>
            <div className="mb-4">
              <Button
                onClick={() => window.open(`bankid:///?autostarttoken=${autoStartToken}&redirect=null`,"_self")}
                title="Använd BankID på den här enheten"
                variant="secondary"
              />
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
          >
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="mb-6 text-2xl font-medium">Logga in</div>
              <div className="mb-6 w-full">
                <Input
                  name="pin"
                  id="pin"
                  label="Personnummer"
                  placeholder="ÅÅMMDD-XXXX"
                  type="text"
                  tooltip="Ange ditt egna personnumer"
                  maxLength={13}
                  value={values.pin}
                  error={touched.pin && errors.pin}
                  onChange={handleChange}
                  onBlur={_handleBlur}
                />
              </div>

              <div className="flex space-x-4">
                <Button
                  onClick={() => history.push('/skapa-konto')}
                  title="Skapa konto"
                  variant="secondary"
                  to="/skapa-konto"
                />
                  {/* disabled={!isValid} */}
                <Button
                  title="Logga in"
                  type="submit"
                  isLoading={isSubmitting}
                  variant="primary"
                />
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default Login
