import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Button, Input } from '@aider/ui'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-hot-toast'

import { intercom } from '@utils/intercom'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Fyll i en giltig e-postadress')
    .required('Obligatoriskt fält.'),
  name: Yup.string()
    .required('Obligatoriskt fält.'),
});

const USER_DETAILS = gql`
  query getUserDetails {
    me {
      id
      email
      name
      address
      city
      zipcode
    }
  }
`

const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $email: String!, $name: String!, $address: String!, $city: String!, $zipcode: String!) {
    updateUser(id: $userId, email: $email, name: $name address: $address, city: $city, zipcode: $zipcode) {
      id
      email
      name
      address
      city
      zipcode
    }
  }
`

const UpdateAccount = ({ onCancel, onSuccess }) => {
  const { data: { me: { id: userId, email = '', name = '', address = '', city = '', zipcode = '' } = {} } = {} } = useQuery(
    USER_DETAILS,
    {
      onError: useCallback(() => {
        toast.error('Kunde inte uppdatera kontoinformationen')
      }, []),
    },
  )

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: useCallback(() => {
      intercom('update', { email, name, user_id: userId, address, city, zipcode })
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }, [onSuccess, email, name, userId, address, city, zipcode]),
    onError: useCallback(() => {
      toast.error('Kunde inte uppdatera kontoinformationen')
    }, []),
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: { email, name, address, city, zipcode },
    onSubmit: async ({ email: userEmail, name: userName, address: userAddress, city: userCity, zipcode: userZipcode }) => {
      updateUser({ variables: { userId, email: userEmail, name: userName, address: userAddress, city: userCity, zipcode: userZipcode } })
    },
  });

  return (
    <div className="p-6 w-96 bg-white rounded-lg">
      <header className="mb-6 text-black text-lg font-medium">
        Uppdatera uppgifter
      </header>
      <form onSubmit={handleSubmit}>
        <Input
        name="name"
        label="Namn"
        placeholder="Förnamn och efternamn"
        type="text"
        value={values.name}
        error={touched.name && errors.name}
        onChange={handleChange}
        onBlur={handleBlur}
        />

        <Input
        name="address"
        label="Adress"
        placeholder="Gatuadress"
        type="text"
        value={values.address}
        error={touched.address && errors.address}
        onChange={handleChange}
        onBlur={handleBlur}
        />

        <Input
        name="city"
        label="Stad"
        placeholder="Stad"
        type="text"
        value={values.city}
        error={touched.city && errors.city}
        onChange={handleChange}
        onBlur={handleBlur}
        />

        <Input
        name="zipcode"
        label="Postnummer"
        placeholder="Postnummer"
        type="text"
        value={values.zipcode}
        error={touched.zipcode && errors.zipcode}
        onChange={handleChange}
        onBlur={handleBlur}
        />

        <Input
          name="email"
          label="E-post"
          placeholder="test@exempel.se"
          type="text"
          value={values.email}
          error={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <div className="flex justify-between pt-6">
          <Button
            variant="tertiary"
            type="button"
            title="Avbryt"
            onClick={onCancel}
          />
          <Button
            title="Spara"
            type="submit"
            isLoading={isSubmitting || loading}
            disabled={isSubmitting || !isValid || loading}
            variant="primary"
          />
        </div>
      </form>
    </div>
  )
}

UpdateAccount.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default UpdateAccount