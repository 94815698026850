import { useCallback, useState, useEffect, useRef } from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { matchPath } from 'react-router'
import { motion, AnimatePresence } from 'framer-motion'
import { Icon, useModal, Loader } from '@aider/ui'
import find from 'lodash.find'
import tw from 'twin.macro'
import ReactSelect, { components } from 'react-select'
import { useActivePrincipal, useOnPremiumOrTrial } from '@hooks/'
import { Pressable, LoadingContainer } from '@components/'
import AccountingLayout from '@features/accounting/AccountingLayout'
import JournalLayout from '@features/journal/JournalLayout'
import ReportsLayout from '@features/reports/ReportsLayout'
import CreatePrincipal from '@features/settings/CreatePrincipal'
import { activePrincipalIdVar } from '@/cache'

const USER_DETAILS = gql`
  query userDetails {
    me {
      id
      principals {
        id
        name
        userByCurrentAuth {
          pivot {
            name
            active
          }
        }
      }
    }
  }
`;

const CustomInput = ({ value, onChange, placeholder }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <input
      ref={inputRef}
      type="text"
      value={value}
      onChange={onChange}
      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-black placeholder-opacity-50 text-black"
      placeholder={placeholder} 
    />
  );
};

const PrincipalLayout = () => {
  const location = useLocation()
  const history = useHistory()
  const { openModal, closeModal, isOpen, Modal } = useModal()
  const { onTrial, onPremium, loading: loadingPremium } = useOnPremiumOrTrial()
  const { activePrincipal, loading } = useActivePrincipal({
    callback: openModal,
  })

  const {
    loading: principalsLoading, data: { me: { principals = [] } = {} } = {},
  } = useQuery(USER_DETAILS)

  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const topOptions = !searchQuery && [
    { value: 'search', label: 'Sök huvudman' },
    { value: 'new-principal', label: 'Ny huvudman' },
    { value: 'handle-principals', label: 'Hantera huvudmän' },
  ];

  const filteredOptions = principals
    .map(({ id, name, userByCurrentAuth }) => {
      if (userByCurrentAuth?.pivot?.active) {
        const principalName = userByCurrentAuth?.pivot?.name ?? name;
        return { value: id, label: principalName };
      }
      return null;
    })
    .filter(option => option !== null)
    .filter(option => option.label.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => {
      if (a.label.toLowerCase().startsWith(searchQuery.toLowerCase())) return -1;
      if (b.label.toLowerCase().startsWith(searchQuery.toLowerCase())) return 1;
      return a.label.localeCompare(b.label);
    });

  const allOptions = searchQuery ? filteredOptions : [...topOptions, ...filteredOptions];

  const handleInputChange = (inputValue) => {
    if (typeof inputValue === 'string') {
      setSearchQuery(inputValue);
    } else {
      setSearchQuery('');  // Fallback to an empty string if inputValue is not a string
    }
  };

  const handleChange = useCallback((value) => {
    if (value?.value === 'search') {
      setIsSearching(true);
      setSearchQuery('');
    } else if (value?.value) {
      setIsSearching(false);
      activePrincipalIdVar(value.value)
    }
  }, []);

  const handleMenuOpen = () => {
    setIsSearching(true);
  };

  const handleBlur = () => {
    const selectedPrincipal = find(allOptions, (o) => o.value === activePrincipal?.id);
    if (!selectedPrincipal) {
      setSearchQuery('');
      setIsSearching(false);
    }
  };

  const CustomControl = ({ children, ...props }) => {
    const selectedPrincipal = find(allOptions, (o) => o.value === activePrincipal?.id);

    return (
      <components.Control {...props}>
        {isSearching ? (
          <CustomInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={selectedPrincipal?.label || 'Skriv för att söka...'} // Show previous name as a placeholder
          />
        ) : (
          children
        )}
      </components.Control>
    );
  };

  const styles = {
    control: (provided, state) => {
      const classes = tw`duration-300 outline-none placeholder-gray-700 transition-shadow rounded-md border hover:border-gray-300 shadow-none border-gray-300 bg-white font-medium ring-blue-300`
      const focusStyles = tw`ring-2 border-blue-500 hover:border-blue-500`
      return state.isFocused
        ? { ...provided, ...classes, ...focusStyles }
        : { ...provided, ...classes };
    },
    option: (_, state) => {
      const defaultClasses = tw`px-3 py-2 rounded-md outline-none text-sm font-medium text-black hover:bg-gray-200 focus:bg-gray-300`;
      const selectedClasses = tw`px-3 py-2 rounded-md outline-none text-sm font-medium text-black focus:bg-gray-200 bg-blue-500 text-white`;
      return state.isFocused ? selectedClasses : defaultClasses
    },
    valueContainer: (provided) => {
      const classes = tw`px-4 py-1 whitespace-nowrap truncate flex-nowrap`
      return { ...provided, ...classes }
    },
    menu: () =>
      tw`border px-1 bg-white border-black border-opacity-10 shadow-sm rounded-lg absolute top-0 w-full mt-12 z-10`,
    placeholder: () => tw`text-base font-medium text-gray-800`,
    singleValue: () => tw`text-base font-medium text-black whitespace-nowrap truncate`,
    noOptionsMessage: () => tw`font-medium text-gray-800 p-2 text-center`,
    loadingMessage: () => tw`px-2 py-6 flex items-center justify-center`,
  }

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <div className="px-2">
        <Icon className="h-3 w-3" name="chevron-down" />
      </div>
    </components.DropdownIndicator>
  )

  const IndicatorSeparator = () => null

  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      Det finns inget att välja
    </components.NoOptionsMessage>
  )

  const LoadingMessage = (props) => (
    <components.LoadingMessage {...props}>
      <Loader />
    </components.LoadingMessage>
  )

  const CustomOption = (props) =>
    props.value === 'new-principal' || props.value === 'handle-principals' ? (
      <div
        ref={props.innerRef}
        css={props.getStyles('option', props)}
        className={props.cx(
          {
            option: true,
            'option--is-disabled': props.isDisabled,
            'option--is-focused': props.isFocused,
            'option--is-selected': props.isSelected,
          },
          props.className,
        )}
        onClick={() =>
          props.value === 'handle-principals'
            ? history.push('/installningar')
            : openModal()
        }
      >
        <div className="flex items-center">
          {props.value === 'new-principal' ? (
            <Icon
              name="add-user"
              className="fill-current text-black w-3 h-3 mr-2"
            />
          ) : (
            <Icon name="cog" className="fill-current text-black w-3 h-3 mr-2" />
          )}
          <span>{props.label}</span>
        </div>
      </div>
    ) : (
      <div
        ref={props.innerRef}
        css={props.getStyles('option', props)}
        className={props.cx(
          {
            option: true,
            'option--is-disabled': props.isDisabled,
            'option--is-focused': props.isFocused,
            'option--is-selected': props.isSelected,
          },
          props.className,
        )}
        {...props.innerProps}
      >
        {props.label}
      </div>
    )

  return (
    <LoadingContainer>
      <div className="flex flex-1 w-full">
        <aside
          style={{ height: `calc(100vh - ${onTrial ? '6rem' : '4rem'})` }}
          className="sticky top-0 print:hidden w-72 border-r border-gray-300"
        >
          <nav className="pr-8 py-8 space-y-8">
            <div className="flex flex-col">
              <div className="mb-3 pb-3 border-b border-gray-300">
                <AnimatePresence exitBeforeEnter>
                  {loading ? (
                    <motion.div
                      key="loading"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="flex items-center justify-center h-12"
                    >
                      <Loader />
                    </motion.div>
                  ) : (
                    <motion.div
                      key="context-picker"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {!activePrincipal && !isSearching ? (
                        <button
                          type="button"
                          className="flex items-center justify-center px-5 w-full h-12 font-medium bg-gray-200 border border-dashed border-gray-600 rounded-lg focus:outline-none"
                          onClick={openModal}
                        >
                          <span className="mr-2">Lägg till huvudman</span>
                          <Icon name="plus" className="w-3 h-3" />
                        </button>
                      ) : (
                        <div>
                          <div className="text-gray-800 text-sm font-medium mb-2">
                            Huvudman
                          </div>
                          <ReactSelect
                            label="Välj en huvudman"
                            placeholder="Välj en huvudman"
                            styles={styles}
                            options={allOptions}
                            onChange={handleChange}
                            value={
                              isSearching
                                ? { value: 'search', label: 'Sök' }
                                : find(allOptions, (o) => o.value === activePrincipal?.id)
                            }
                            isSearchable
                            inputValue={searchQuery}
                            onInputChange={handleInputChange}
                            onMenuOpen={handleMenuOpen}
                            onBlur={handleBlur} // Handle blur event
                            menuIsOpen={isSearching || undefined}
                            components={{
                              Control: CustomControl,
                              DropdownIndicator,
                              IndicatorSeparator,
                              NoOptionsMessage,
                              LoadingMessage,
                              Option: CustomOption,
                            }}
                          />
                        </div>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <div className="flex flex-col">
                {/* Dagbok menu item */}
                <PrimaryLink
                  to="/huvudman/dagbok"
                  title="Dagbok & körjournal"
                  icon="book"
                />
                <AnimatePresence initial={false}>
                  {location.pathname.includes('dagbok') && (
                    <motion.div
                      className="flex flex-col overflow-hidden"
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: 'auto', scale: 1 },
                        collapsed: { opacity: 0, height: 0, scale: 0.9 },
                      }}
                      transition={{ duration: 0.25 }}
                    >
                      <SecondaryLink
                        to="/huvudman/dagbok/inlagg"
                        title="Inlägg"
                      />
                      <SecondaryLink
                        to="/huvudman/dagbok/oversikt"
                        title="Sammanfattning & utskrift"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* Redovisning menu item */}
                <PrimaryLink
                  to="/huvudman/redovisning/"
                  title="Redovisning"
                  isPremium={!onPremium && !loadingPremium}
                  icon="inbox"
                  hasChildren
                />
                <AnimatePresence initial={false}>
                  {location.pathname.includes('redovisning') && (
                    <motion.div
                      className="flex flex-col overflow-hidden"
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: 'auto', scale: 1 },
                        collapsed: { opacity: 0, height: 0, scale: 0.9 },
                      }}
                      transition={{ duration: 0.25 }}
                    >
                      <SecondaryLink
                        to="/huvudman/redovisning/transaktioner"
                        title="Transaktioner"
                      />
                      <SecondaryLink
                        to="/huvudman/redovisning/bokforing"
                        title="Bokföring"
                      />
                      <SecondaryLink
                        to="/huvudman/redovisning/oversikt"
                        title="Översikt"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* Rapporter menu item */}
                <PrimaryLink
                  title="Rapporter"
                  icon="graph"
                  isPremium={!onPremium && !loadingPremium}
                  to="/huvudman/rapporter"
                />
              </div>
            </div>
          </nav>
        </aside>
        <main className="relative flex flex-1 flex-col flex-shrink-0 pt-8 pl-6 md:pl-8 pr-4">
          <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={location} key={location?.pathname}>
              <Route path="/huvudman/dagbok" component={JournalLayout} />
              <Route path="/huvudman/redovisning" component={AccountingLayout} />
              <Route path="/huvudman/rapporter" component={ReportsLayout} />
              <Route render={() => <Redirect to="/huvudman/dagbok" />} />
            </Switch>
          </AnimatePresence>
        </main>
      </div>
      <Modal visible={isOpen}>
        <CreatePrincipal onSuccess={closeModal} onCancel={closeModal} />
      </Modal>
    </LoadingContainer>
  );
};

const PrimaryLink = ({
  title,
  hasChildren,
  icon,
  to,
  isPremium,
  ...props
}) => {
  const { pathname } = useLocation();
  const active = matchPath(pathname, to);

  return (
    <Pressable
      className={`${
        active ? 'text-blue-600' : 'text-black'
      }  focus:outline-none py-3 flex justify-between items-center transition-colors duration-250`}
      to={to}
      {...props}
    >
      <div className="flex items-center">
        <div className="relative mr-2">
          {isPremium && (
            <div className="absolute -right-2 -top-2 flex items-center justify-center w-4 h-4 bg-blue-600 rounded-md">
              <Icon
                name="diamond"
                className="w-2.5 h-2.5 text-blue-200 fill-current"
              />
            </div>
          )}
          <Icon name={icon} className="w-4 h-4 fill-current" />
        </div>
        <div className="block text-base font-medium">{title}</div>
      </div>
      {hasChildren && (
        <Icon
          name="chevron-down"
          className={`${
            active && 'rotate-180'
          } transform transition-transform duration-250 h-2.5 w-2.5 text-black fill-current`}
        />
      )}
    </Pressable>
  );
};

const SecondaryLink = ({ title, to, ...props }) => {
  const { pathname } = useLocation();
  const active = matchPath(pathname, to)

  return (
    <Pressable
      className={`${
        active ? 'text-blue-600' : 'text-black'
      } text-base flex items-center pl-4 font-medium focus:outline-none py-2 transition-colors duration-250`}
      to={to}
      {...props}
    >
      <div
        className={`h-1.5 w-1.5 mr-2 rounded-full ${
          active ? 'bg-blue-600' : 'bg-black'
        }`}
      />
      <div>{title}</div>
    </Pressable>
  )
}

export default PrincipalLayout
