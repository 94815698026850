import { useFormik } from 'formik'
import 'yup-personnummer'
import * as Yup from 'yup'
import Personnummer from 'personnummer'
import to from 'await-to-fetch'
import { Button, Checkbox, Input, Loader, Icon } from '@aider/ui'
import { motion, AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import ReactGA from 'react-ga4'
import QRCode from "react-qr-code";
import { useState } from "react";

import { track, events } from '@utils/analytics'
import { isLoggedInVar } from '@/cache'

import authWithBankId from './authWithBankId'

const validationSchema = Yup.object().shape({
  pin: Yup.string()
    .required('Obligatoriskt fält.')
    .personnummer('Fyll i ett giltigt personnummer'),
  email: Yup.string()
    .required('Obligatoriskt fält.')
    .email('Fyll i en giltig e-postadress'),
  namn: Yup.string()
    .required('Obligatoriskt fält.')
    .min(2, 'Namn måste vara minst 2 tecken långt'),
  adress: Yup.string()
    .required('Obligatoriskt fält.')
    .min(5, 'Adress måste vara minst 5 tecken lång'),
  stad: Yup.string()
    .required('Obligatoriskt fält.'),
  postnummer: Yup.string()
    .required('Obligatoriskt fält.')
    .matches(/^\d{5}$/, 'Postnummer måste vara 5 siffror långt'),
  confirmation: Yup.bool().oneOf([true], 'Du måste godkänna'),
})

const Register = () => {
  const [qrCodeData, setQrCodeData] = useState("xyz");
  const [autoStartToken, setAutoStartToken] = useState("");
  const history = useHistory()

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    isValid,
  } = useFormik({
    initialErrors: false,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: { 
      pin: '', 
      email: '', 
      namn: '', 
      adress: '', 
      stad: '', 
      postnummer: '', 
      confirmation: false 
    },
    onSubmit: async ({ pin, email, namn, adress, stad, postnummer }) => {
      const params = new URLSearchParams(document.location.search)
      const inviteCode = params.get('inviteCode')

      const [error, response] = await to(
        authWithBankId(
          { 
            pin: Personnummer.parse(pin).format('long'), 
            email, 
            namn,
            adress,
            stad,
            postnummer,
            inviteCode 
          },
          true,
          setQrCodeData,
          setAutoStartToken,
        ),
      )

      if (error) {
        return toast.error('Kunde inte skapa ny användare')
      }

      const { token: { plainTextToken } = {}, expires_at: expires } = response

      // Create a timestamp from expires_at and convert it to seconds
      const expiresAt = new Date(expires).getTime() / 1000
      localStorage.setItem('accessToken', plainTextToken)
      localStorage.setItem('expiresAt', expiresAt)

      // Log conversion to Google Analytics
      // Send a custom event that the user have signed up
      ReactGA.event('sign_up')

      track(events.USER_LOGGED_IN)
      return isLoggedInVar(true)
    },
  })

  // Patch handleBlur to autocorrect personnummer
  const _handleBlur = event => {
    const { value } = event.target

    if (Personnummer.valid(value)) {
      setFieldValue('pin', Personnummer.parse(value).format())
    }

    handleBlur(event)
  }

  return (
    <motion.div
      key="create-account"
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.2 }}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {isSubmitting ? (
          <motion.div
            key="loading"
            className="inset-x-0 flex flex-col items-center mx-auto p-4 w-full text-center bg-white border border-gray-300 rounded-xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
          >
            <Icon name="bank-id" className="mb-4 w-10 h-10" />
            <span className="block mb-2 text-black font-semibold">
              Öppna BankID
            </span>
            <span className="mb-8 max-w-xs text-gray-800">
              För att skapa ditt konto, Starta BankID-appen på din mobil eller surfplatta och tryck på &quot;Skanna QR-kod&quot;.
            </span>
            <div className="mb-4">
              <QRCode value={qrCodeData} />
            </div>
            <div className="mb-4">
              <Button
                onClick={() => window.open(`bankid:///?autostarttoken=${autoStartToken}&redirect=null`,"_self")}
                title="Använd BankID på den här enheten"
                variant="secondary"
              />
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
          >
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="mb-6 text-2xl font-medium">Skapa konto</div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
                <Input
                  name="pin"
                  id="pin"
                  label="Personnummer"
                  placeholder="ÅÅMMDD-XXXX"
                  type="text"
                  maxLength={13}
                  tooltip="Ange ditt egna personnumer"
                  value={values.pin}
                  error={touched.pin && errors.pin}
                  onChange={handleChange}
                  onBlur={_handleBlur}
                />
                <Input
                  name="namn"
                  id="namn"
                  label="Namn"
                  placeholder="För och Efternamn"
                  type="text"
                  value={values.namn}
                  error={touched.namn && errors.namn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="col-span-1 md:col-span-2">
                  <Input
                    name="email"
                    id="email"
                    label="E-post"
                    placeholder="namn@exempel.se"
                    type="email"
                    value={values.email}
                    error={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <Input
                  name="postnummer"
                  id="postnummer"
                  label="Postnummer"
                  placeholder="Ditt postnummer"
                  type="text"
                  maxLength={5}
                  value={values.postnummer}
                  error={touched.postnummer && errors.postnummer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  name="stad"
                  id="stad"
                  label="Stad"
                  placeholder="Din stad"
                  type="text"
                  value={values.stad}
                  error={touched.stad && errors.stad}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="col-span-1 md:col-span-2">
                  <Input
                    name="adress"
                    id="adress"
                    label="Adress"
                    placeholder="Din adress"
                    type="text"
                    value={values.adress}
                    error={touched.adress && errors.adress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-span-1 md:col-span-2 flex items-center space-x-2">
                  <label htmlFor="confirmation" className="text-sm font-medium">
                    Jag godkänner användningsvillkoren
                  </label>
                  <Checkbox
                    name="confirmation"
                    id="confirmation"
                    checked={values.confirmation}
                    onCheckedChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="flex space-x-4">
                <Button
                  onClick={() => history.push('/login')}
                  title="Avbryt"
                  variant="secondary"
                />
                <Button
                  disabled={!isValid}
                  title="Skapa konto"
                  type="submit"
                  isLoading={isSubmitting}
                  variant="primary"
                />
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default Register
