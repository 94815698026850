import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Avatar, useModal } from '@aider/ui'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'

import { LoadingContainer } from '@components/'
import { useSubscriptionStatus } from '@hooks/'
import { goToStripeCustomerPortal } from '@utils/stripe'
import { logout } from '@/apollo-client'

import CreatePrincipal from './CreatePrincipal'
import DeletePrincipal from './DeletePrincipal'
import UpdateAccount from './UpdateAccount'
import DeleteAccount from './DeleteAccount'
import ExportAccount from './ExportAccount'

const USER_DETAILS = gql`
  query userDetails {
    me {
      id
      name
      email
      principals {
        id
        name
        decryptedPin
        userByCurrentAuth {
          pivot {
            name
            active
          }
        }
      }
    }
  }
`

const Settings = () => {
  const [deletePrincipalId, setDeletePrincipalId] = useState(null)
  const { loading: loadingSubscriptionStatus, status } = useSubscriptionStatus()

  const {
    openModal: openUpdateAccountModal,
    closeModal: closeUpdateAccountModal,
    isOpen: isUpdateAccountOpen,
    Modal: UpdateAccountModal,
  } = useModal()

  const {
    openModal: openCreatePrincipalModal,
    closeModal: closeCreatePrincipalModal,
    isOpen: isCreatePrincipalOpen,
    Modal: CreatePrincipalModal,
  } = useModal()

  const {
    openModal: openDeletePrincipalModal,
    closeModal: closeDeletePrincipalModal,
    isOpen: isDeletePrincipalOpen,
    Modal: DeletePrincipalModal,
  } = useModal()

  const {
    openModal: openDeleteAccountModal,
    closeModal: closeDeleteAccountModal,
    isOpen: isDeleteAccountOpen,
    Modal: DeleteAccountModal,
  } = useModal()

  const {
    openModal: openExportAccountModal,
    closeModal: closeExportAccountModal,
    isOpen: isExportAccountOpen,
    Modal: ExportAccountModal,
  } = useModal()

  const { loading, data: { me: { name, email, principals = [] } = {} } = {} } =
    // useQuery(USER_DETAILS)
    // Line above creates a problem because of the cache, lets turn it off for now
    useQuery(USER_DETAILS, { fetchPolicy: 'network-only' })

  // Sort principals by name asc, use pivot.name if available
  const principalsSorted = [...principals].sort((a, b) => {
    const aActive = a.userByCurrentAuth?.pivot?.active ? 1 : 0;
    const bActive = b.userByCurrentAuth?.pivot?.active ? 1 : 0;

    if (aActive !== bActive) {
      return bActive - aActive;
    }

    const aName = a.userByCurrentAuth?.pivot?.name || a.name;
    const bName = b.userByCurrentAuth?.pivot?.name || b.name;

    return aName.localeCompare(bName);
  });

  const onOpenPrincipalModal = (e, id) => {
    setDeletePrincipalId(id)
    openDeletePrincipalModal(e)
  }

  const onDeletePrincipalModalClose = () => {
    setDeletePrincipalId(null)
  }

  return (
    <LoadingContainer loading={loading || loadingSubscriptionStatus}>
      <div className="mx-auto pt-16 w-full lg:w-10/12 xl:w-8/12">
        <section className="mb-20">
          <div className="sticky z-10 top-0 py-4 text-2xl font-medium bg-white">
            Ditt konto
          </div>
          {/* Account Info */}
          <div className="flex items-center justify-between py-4">
            <div className="flex items-center space-x-2">
              <Avatar name={name} />
              <div>
                <div className="font-medium leading-tight">{name}</div>
                <div className="text-gray-800 leading-tight">{email}</div>
              </div>
            </div>
            <div className="flex space-x-3">
              <button
                type="button"
                className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
                onClick={openUpdateAccountModal}
              >
                Redigera
              </button>
              <button
                type="button"
                className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
                onClick={logout}
              >
                Logga ut
              </button>
            </div>
          </div>
          {/* Export Account Data */}
          <div className="flex items-center justify-between py-4 border-t border-gray-300">
            <div>
              <div className="font-medium leading-tight">Exportera data</div>
              <div className="text-gray-800 leading-tight">
                Hämta all information från Aider i .json-format
              </div>
            </div>
            <button
              type="button"
              className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
              onClick={openExportAccountModal}
            >
              Exportera
            </button>
          </div>
          {/* Subscription */}
          <div className="flex items-center justify-between py-4 border-t border-gray-300">
            <div>
              <div className="font-medium leading-tight">Prenumeration</div>
              <div className="text-gray-800 leading-tight">{status}</div>
            </div>
            <button
              type="button"
              className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
              onClick={goToStripeCustomerPortal}
            >
              Hantera prenumeration
            </button>
          </div>
          {/* Delete Account */}
          <div className="flex items-center justify-between py-4 border-t border-gray-300">
            <div>
              <div className="font-medium leading-tight">Radera konto</div>
              <div className="text-gray-800 leading-tight">
                Ta bort ditt konto och all data permanent
              </div>
            </div>
            <button
              type="button"
              className="text-red font-medium focus:outline-none hover:opacity-75 transition-opacity"
              onClick={openDeleteAccountModal}
            >
              Radera
            </button>
          </div>
        </section>
        <section className="mb-20">
          {/* Principals */}
          <div className="sticky z-10 top-0 flex items-center justify-between py-4 bg-white">
            <div className="text-2xl font-medium">Dina huvudmän</div>
            <button
              type="button"
              className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
              onClick={openCreatePrincipalModal}
            >
              Lägg till huvudman
            </button>
          </div>
          <AnimateSharedLayout>
            <motion.ul layout>
              <AnimatePresence initial={false}>
                {principalsSorted.map(
                  ({
                    id,
                    decryptedPin,
                    userByCurrentAuth,
                    name: principalName,
                  }) => (
                    <motion.li
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 50 }}
                      layout
                      key={id}
                    >
                      <div className="flex items-center justify-between py-4 border-t border-gray-300">
                        <div>
                          <div className="font-medium leading-tight">
                            {userByCurrentAuth?.pivot?.name !== null &&
                            userByCurrentAuth?.pivot?.name !== undefined
                              ? userByCurrentAuth?.pivot?.name
                              : principalName}
                            {userByCurrentAuth?.pivot?.active === false ? (
                              <span className="text-red font-medium">
                                {' '}
                                (inaktiv)
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="text-gray-800 leading-tight">
                            {decryptedPin}
                          </div>
                        </div>
                        <div className="space-x-4">
                          <Link
                            className="text-blue-600 hover:opacity-75 transition-opacity font-medium"
                            to={`/installningar/huvudman/${id}`}
                          >
                            Redigera
                          </Link>
                          <button
                            type="button"
                            className="text-red hover:opacity-75 transition-opacity font-medium"
                            onClick={e => onOpenPrincipalModal(e, id)}
                          >
                            Radera
                          </button>
                        </div>
                      </div>
                    </motion.li>
                  ),
                )}
              </AnimatePresence>
            </motion.ul>
          </AnimateSharedLayout>
        </section>
      </div>
      {/* Modals */}
      <UpdateAccountModal visible={isUpdateAccountOpen}>
        <UpdateAccount
          onSuccess={closeUpdateAccountModal}
          onCancel={closeUpdateAccountModal}
        />
      </UpdateAccountModal>
      <CreatePrincipalModal visible={isCreatePrincipalOpen}>
        <CreatePrincipal
          onSuccess={closeCreatePrincipalModal}
          onCancel={closeCreatePrincipalModal}
        />
      </CreatePrincipalModal>
      <DeletePrincipalModal
        visible={isDeletePrincipalOpen}
        onClose={onDeletePrincipalModalClose}
      >
        <DeletePrincipal
          principalId={deletePrincipalId}
          onSuccess={closeDeletePrincipalModal}
          onCancel={closeDeletePrincipalModal}
        />
      </DeletePrincipalModal>
      <DeleteAccountModal visible={isDeleteAccountOpen}>
        <DeleteAccount
          onSuccess={closeDeleteAccountModal}
          onCancel={closeDeleteAccountModal}
        />
      </DeleteAccountModal>

      <ExportAccountModal visible={isExportAccountOpen}>
        <ExportAccount
          onSuccess={closeExportAccountModal}
          onCancel={closeExportAccountModal}
          email={email}
        />
      </ExportAccountModal>
    </LoadingContainer>
  )
}

export default Settings
