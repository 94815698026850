import { useEffect, useMemo, useState, useCallback } from 'react'
import { useReactiveVar, useQuery, gql } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { Button, useModal } from '@aider/ui'

import {
  LoadingContainer,
  CurrencyCell,
  DateCell,
  EmptyState,
  StatusCell,
} from '@components/'
import TransactionTable from '@components/table/TransactionTable'
import EmptyTransactions from '@assets/images/empty_transactions.png'
import { useEventEmitter, events } from '@hooks/useEventEmitter'
import { activePrincipalIdVar } from '@/cache'

import CreateTransaction from './CreateTransaction'
import DeleteTransactions from './DeleteTransactions'
import UpdateTransactions from './UpdateTransactions'
import TransactionsFilter from './transactions-filter'

export const TRANSACTIONS = gql`
  query transactions(
    $principalIds: [ID]
    $dateRange: DateRange
    $amountRange: AmountRange
    $verificationAccountIds: [ID!]
    $verified: Boolean
    $after: String
  ) {
    me {
      id
      transactions(
        first: 50
        after: $after
        orderBy: [
          { column: DATE, order: DESC }
          { column: UPDATED_AT, order: DESC }
        ]
        principalIds: $principalIds
        date: $dateRange
        amount: $amountRange
        verificationAccountIds: $verificationAccountIds
        verified: $verified
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            description
            amount
            verified
            date
            attachments {
              id
            }
            verifications {
              tag {
                id
              }
            }
          }
        }
      }
    }
  }
`

const Transactions = () => {
  const history = useHistory()
  const eventEmitter = useEventEmitter()
  const principalId = useReactiveVar(activePrincipalIdVar)
  const [selectedTransactions, setSelectedTransactions] = useState([])
  const [visibleTransactions, setVisibleTransactions] = useState([])
  const [selectedTransactionAmounts, setSelectedTransactionAmounts] = useState([])
  const [expensesVisible, setExpensesVisible] = useState(true)
  const [incomesVisible, setIncomesVisible] = useState(true)
  const [loading, setLoading] = useState(false);

  // Create transaction modal
  const {
    openModal: openCreateTransactionModal,
    closeModal: closeCreateTransactionModal,
    isOpen: createTransactionOpen,
    Modal: CreateTransactionModal,
  } = useModal()

  // Delete transactions modal
  const {
    openModal: openDeleteTransactionsModal,
    closeModal: closeDeleteTransactionsModal,
    isOpen: deleteTransactionsOpen,
    Modal: DeleteTransactionsModal,
  } = useModal()

  // Update transactions modal
  const {
    openModal: openUpdateTransactionsModal,
    closeModal: closeUpdateTransactionsModal,
    isOpen: updateTransactionsOpen,
    Modal: UpdateTransactionsModal,
  } = useModal()

  const {
    data: {
      me: {
        transactions: {
          pageInfo: { endCursor, hasNextPage } = {},
          edges: transactions = [],
        } = {},
      } = {},
    } = {},
    loading: loadingTransactions,
    fetchMore,
    refetch,
  } = useQuery(TRANSACTIONS, {
    // Updating paginated lists with multiple arguments in the cache is too complex,
    // so we'll just use 'network-only' for now.
    // @see https://github.com/apollographql/apollo-client/issues/2991
    // @see https://medium.com/@martinseanhunt/how-to-invalidate-cached-data-in-apollo-and-handle-updating-paginated-queries-379e4b9e4698
    fetchPolicy: 'network-only',
    variables: { principalIds: [principalId] },
  })


  const onFilterUpdate = filters => {
    setLoading(true);
    refetch({ ...filters }).finally(() => setLoading(false));
  }

  const displayIncomeTransactions = (transactionRows, selectedIds) => {
    const incomeTransactions = transactionRows.filter(transaction => (transaction?.node?.amount > 0)).map(transaction => {
      if (selectedIds.includes(transaction?.node?.id)) {
        // eslint-disable-next-line no-param-reassign
        transaction.defaultChecked = true
      }
      return transaction
    })
    setVisibleTransactions(incomeTransactions)
  }

  const displayExpenseTransactions = (transactionRows, selectedIds) => {
    const expenseTransactions = transactionRows.filter(transaction => (transaction?.node?.amount < 0)).map(transaction => {
      if (selectedIds.includes(transaction?.node?.id)) {
        // eslint-disable-next-line no-param-reassign
        transaction.defaultChecked = true
      }
      return transaction
    })
    setVisibleTransactions(expenseTransactions)
  }


  const handleSelectedRowsChange = useCallback(selected => {
    let countIncome = 0
    let countExpense = 0
    const ids = []
    const amounts = []
    selected.forEach(({ id, amount } = {}) => {
      ids.push(id)
      amounts.push(amount)
      if (amount > 0) {
        countIncome += 1;
      } else {
        countExpense += 1;
      }
    });
    if (selected.length === 0 || (countIncome && countExpense)) {
      setExpensesVisible(true)
      setIncomesVisible(true)
    } else if (selected.length > 0) {
      if (countIncome > 0) {
        setExpensesVisible(false)
        setIncomesVisible(true)
      } else {
        setExpensesVisible(true)
        setIncomesVisible(false)
      }
    }
    setSelectedTransactions(ids)
    setSelectedTransactionAmounts(amounts)
  }, [])

  useEffect(() => {
    if (loading) {
      // Return a no-op function when loading
      return () => {};
    }

    if (incomesVisible && expensesVisible) {
      const allTransactions = []
      transactions.forEach(transaction => {
        const row = transaction
        delete row.defaultChecked
        allTransactions.push(row)
      })
      setVisibleTransactions(allTransactions)
    } else if (expensesVisible) {
      displayExpenseTransactions(transactions, selectedTransactions)
    } else {
      displayIncomeTransactions(transactions, selectedTransactions)
    }
    const unsubscribe = eventEmitter.on(events.CONTENT_SCROLL_BOTTOM, () => {
      if (!hasNextPage || loadingTransactions) return
      fetchMore({ variables: { after: endCursor } })
    })

    return () => unsubscribe()
  }, [eventEmitter, fetchMore, loadingTransactions, hasNextPage, endCursor, incomesVisible, expensesVisible, transactions, loading])

  const columns = useMemo(
    () => [
      {
        Header: 'Beskrivning',
        accessor: 'node',
        Cell: ({ cell: { value: { description, id } = {} } = {} }) => (
          <div className="text-black font-medium truncate">
            <Link to={`/huvudman/redovisning/transaktioner/${id}`}>
              {description || 'Saknar beskrivning'}
            </Link>
          </div>
        ),
      },
      {
        Header: 'Belopp',
        accessor: 'node.amount',
        Cell: ({ value }) => (
          <div className="text-black font-medium truncate">
            <CurrencyCell amount={value} />
          </div>
        ),
      },
      {
        Header: 'Datum',
        accessor: 'node.date',
        Cell: ({ value }) => (
          <div className="text-black font-medium truncate">
            <DateCell date={value} />
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'node.verified',
        Cell: props => (
          <div className="text-black font-medium truncate">
            <StatusCell
              verified={props.row.values.node.verified}
              verifications={props.row.values.node.verifications}
              hasAttachment={props.row.values.node.attachments.length > 0}
            />
          </div>
        ),
      },
    ],
    [],
  )

  function onRowClick(transactionId) {
    history.push(`/huvudman/redovisning/transaktioner/${transactionId}`)
  }

  return (
    <LoadingContainer loading={loadingTransactions}>
      <header className="sticky z-20 top-0 bg-white">
        <div className="flex items-center justify-between h-20">
          <AnimatePresence exitBeforeEnter initial={false}>
            {selectedTransactions.length ? (
              <motion.div
                key="selectedTransactions"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.2 }}
                className="flex items-center justify-between w-full"
              >
                <div className="text-2xl font-medium">
                  {selectedTransactions.length} valda rader
                </div>
                <div className="flex space-x-3">
                  {Array.isArray(transactions) && !(expensesVisible && incomesVisible) &&
                    <Button
                      type="button"
                      title="Bokför alla"
                      disabled={!selectedTransactions.length}
                      onClick={openUpdateTransactionsModal}
                    />
                  }
                  <Button
                    type="button"
                    title="Radera"
                    variant="destructive"
                    disabled={!selectedTransactions.length}
                    onClick={openDeleteTransactionsModal}
                  />
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="tools"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.2 }}
                className="flex items-center justify-between w-full"
              >
                <div className="text-2xl font-medium">Transaktioner</div>
                <div className="space-x-3">
                  <Button
                    title="Ny transaktion"
                    variant="secondary"
                    onClick={openCreateTransactionModal}
                  />
                  <Button
                    title="Importera"
                    variant="primary"
                    onClick={() =>
                      history.push(
                        '/huvudman/redovisning/transaktioner/importera',
                      )
                    }
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </header>
      <div className="flex justify-between pb-6">
        <p className="md:w-8/12">
          Här importerar du transaktioner från det av huvudmannens bankkonto du
          är skyldig att redovisa. Klicka på knappen <i>Importera</i> för att
          hämta transaktioner. När transaktionerna väl är hämtade listas de här,
          med status som visar om de är bokförda eller ej.
        </p>
      </div>
      <div className="text-lg font-medium pb-2">Filter</div>
      <div className="flex justify-between relative z-10">
        <TransactionsFilter onFilterUpdate={onFilterUpdate} />
      </div>
      {Array.isArray(transactions) && transactions.length ? (
        <div className="mb-20">
          <TransactionTable
            columns={columns}
            data={visibleTransactions.map(txn => txn.node)}
            onSelectedRowsChange={handleSelectedRowsChange}
            onRowClick={onRowClick}
            selectable
          />
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center mt-36">
          <EmptyState
            icon={
              <img
                className="mb-2 w-16 h-auto"
                src={EmptyTransactions}
                alt="transaktioner"
              />
            }
            title="Du har inte importerat några transaktioner än"
            description="Börja med att importera transaktioner från banken, för att komma igång med att bokföra."
            action={
              <Button
                title="Importera"
                variant="secondary"
                onClick={() =>
                  history.push('/huvudman/redovisning/transaktioner/importera')
                }
                disabled={!principalId}
              />
            }
          />
        </div>
      )}
      <CreateTransactionModal visible={createTransactionOpen}>
        <CreateTransaction
          onCancel={closeCreateTransactionModal}
          onSuccess={closeCreateTransactionModal}
        />
      </CreateTransactionModal>
      <DeleteTransactionsModal visible={deleteTransactionsOpen}>
        <DeleteTransactions
          onCancel={closeDeleteTransactionsModal}
          onSuccess={() => {
            closeDeleteTransactionsModal()
            setSelectedTransactions([])
            setSelectedTransactionAmounts([])
          }}
          transactionIds={selectedTransactions}
        />
      </DeleteTransactionsModal>
      <UpdateTransactionsModal visible={updateTransactionsOpen}>
        <UpdateTransactions
          onCancel={closeUpdateTransactionsModal}
          onSuccess={() => {
            closeUpdateTransactionsModal()
            setSelectedTransactions([])
            setSelectedTransactionAmounts([])
          }}
          transactionIds={selectedTransactions}
          transactionAmounts={selectedTransactionAmounts}
          expensesVisible={expensesVisible}
        />
      </UpdateTransactionsModal>
    </LoadingContainer>
  )
}

export default Transactions
