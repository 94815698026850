import { useState, useMemo, useEffect, useCallback, Fragment } from 'react'
import { useReactiveVar, gql, useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { Button, useModal, Dropdown, FilterButton, Datepicker } from '@aider/ui'
import { format as fnsFormat } from 'date-fns'

import { LoadingContainer, EmptyState } from '@components/'
import { useEventEmitter, events } from '@hooks/useEventEmitter'
import EmptyJournal from '@assets/images/empty_journal.png'
import { getFirstBlockAsText } from '@utils/draft-helpers'
import { dateToYearRange, format } from '@utils/date'
import { activePrincipalIdVar } from '@/cache'

import JournalEntryCard from './JournalEntryCard'
import CreateJournalEntry from './CreateJournalEntry'

const JOURNAL_ENTRIES = gql`
  query journalEntries(
    $principalId: ID!
    $after: String
    $dateRange: DateRange
  ) {
    principal(id: $principalId) {
      id
      name
      journalEntries(
        date: $dateRange
        after: $after
        first: 50
        orderBy: [
          { column: DATE, order: DESC }
          { column: UPDATED_AT, order: DESC }
        ]
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            principal {
              id
            }
            id
            description
            minutes
            date
            journalEntryCategory {
              id
              name
            }
            drivingLogEntry {
              id
              kilometers
            }
          }
        }
      }
    }
  }
`

const DELETE_JOURNAL_ENTRY = gql`
  mutation deleteJournalEntry($journalEntryId: ID!) {
    deleteJournalEntry(id: $journalEntryId) {
      id
    }
  }
`

/**
 * Convert minutes to a human readable string
 * @param  {Number} num Number of minutes to convert
 * @return {String}     A human readable string like '2 timmar och 5 minuter'.
 */
const formatMinutes = num => {
  const hours = Math.floor(num / 60)
  const minutes = Math.round(num % 60)

  const hoursNoun = hours > 1 ? 'timmar' : 'timme'
  const hoursStr = hours ? `${hours} ${hoursNoun}` : ''
  const minutesStr = minutes ? `${minutes} minuter` : ''
  const delimiter = hours && minutes ? ' och ' : ''

  if (!num) return '0 minuter'

  return hoursStr + delimiter + minutesStr
}

/**
 * Sort journal entries by month
 * @param  {Array} arr Array of transactions or journal entries
 * @return {Array}     Sorted array prepared for a SectionList
 */
const sortByMonth = arr =>
  arr.reduce((acc, { node: item } = {}) => {
    // Use the first 7 characters, they represent the month
    const date = item.date.substring(0, 7)

    const index = acc.findIndex(x => x.title === date)

    if (index > -1) {
      acc[index].data.push(item)
    } else {
      acc.push({ title: date, data: [item] })
    }

    // Sorts based on date
    acc.sort((x, y) => new Date(x.title) - new Date(y.title))

    // Reverse order of array
    return acc.reverse()
  }, [])

const Journal = () => {
  const eventEmitter = useEventEmitter()
  const { openModal, closeModal, isOpen, Modal } = useModal()

  const [editJournalEntryId, setEditJournalEntryId] = useState(null)
  const principalId = useReactiveVar(activePrincipalIdVar)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const dateRange = useMemo(() => dateToYearRange(selectedDate), [selectedDate])

  const {
    data: {
      principal: {
        journalEntries: {
          pageInfo: { endCursor, hasNextPage } = {},
          edges: journalEntries = [],
        } = {},
      } = {},
    } = {},
    loading: loadingJournalEntries,
    fetchMore,
  } = useQuery(JOURNAL_ENTRIES, {
    skip: !principalId,
    fetchPolicy: 'network-only',
    variables: { principalId, dateRange },
    onError: useCallback(() => {
      toast.error('Vi kunde inte hämta dagboksinlägg')
    }, []),
  })

  useEffect(() => {
    const unsubscribe = eventEmitter.on(events.CONTENT_SCROLL_BOTTOM, () => {
      if (!hasNextPage || loadingJournalEntries) return
      fetchMore({ variables: { after: endCursor } })
    })

    return () => unsubscribe()
  }, [eventEmitter, fetchMore, loadingJournalEntries, hasNextPage, endCursor])

  const [deleteJournalEntry] = useMutation(DELETE_JOURNAL_ENTRY, {
    update(cache, { data: { deleteJournalEntry: entry } = {} } = {}) {
      cache.evict({ id: cache.identify(entry) })
    },
    onError: useCallback(() => {
      toast.error('Kunde inte ta bort dagboksinlägget')
    }, []),
  })

  const onJournalEntryEdit = (e, id) => {
    setEditJournalEntryId(id)
    openModal(e)
  }

  const months = useMemo(() => sortByMonth(journalEntries), [journalEntries])

  return (
    <Fragment>
      <LoadingContainer>
      <div className="flex flex-col items-start justify-start w-full mx-auto px-4 sm:px-6 lg:px-8">
      <header className="w-full flex flex-col sm:flex-row items-center justify-between h-20 bg-white mb-4">
      <div className="flex items-center space-x-4">
        <div className="text-2xl font-medium text-center sm:text-left">Dagbok & körjournal</div>
        <Dropdown.Root>
          <Dropdown.Trigger
            title="Period"
            value={fnsFormat(selectedDate, 'yyyy')}
            as={FilterButton}
          />
          <Dropdown.Content align="start" sideOffset={6}>
            <Datepicker
              selected={selectedDate}
              startDate={selectedDate}
              onChange={d => setSelectedDate(d)}
              showYearPicker
              inline
            />
          </Dropdown.Content>
        </Dropdown.Root>
      </div>
      <div className="mt-2 sm:mt-0">
        <Button
          title="Nytt inlägg"
          variant="primary"
          onClick={openModal}
          disabled={!principalId}
        />
      </div>
    </header>
    <div className="w-full md:w-9/12 mt-4 text-center sm:text-left">
      <p>
        Här samlar och sparar du anteckningar om allt du utför i ditt
        uppdrag. Alla dina inlägg sammanställs i en strukturerad dagbok och
        körjournal, som du kan bifoga till din års- eller slutredogörelse.
      </p>
    </div>
    {months.length ? (
      <div className="w-full mt-8">
        {months.map(({ title, data }) => (
          <section key={title} className="block mb-6">
            <header className="flex-1 py-4 bg-white">
              <span className="text-lg font-medium capitalize">
                {format(title, 'MMMM yyy')}
              </span>
            </header>
            <div className="flex flex-wrap -mx-3">
              {data.map(
                ({
                  id,
                  description,
                  journalEntryCategory,
                  drivingLogEntry,
                  minutes,
                  date,
                }) => (
                  <div
                    key={id}
                    className="flex self-stretch pb-4 px-3 w-full md:w-6/12"
                  >
                    <JournalEntryCard
                      description={getFirstBlockAsText(description)}
                      category={journalEntryCategory.name}
                      kilometers={drivingLogEntry?.kilometers}
                      minutes={formatMinutes(minutes)}
                      date={date}
                      onEdit={e => onJournalEntryEdit(e, id)}
                      onDelete={() =>
                        deleteJournalEntry({
                          variables: { journalEntryId: id },
                        })
                      }
                    />
                  </div>
                ),
              )}
            </div>
          </section>
        ))}
      </div>
    ) : (
      <div className="flex items-center justify-center w-full h-full mt-8">
        <EmptyState
          icon={
            <img
              className="mb-2 w-16 h-auto"
              src={EmptyJournal}
              alt="dagboksinlägg"
            />
          }
          title="Du har inte skapat några inlägg än"
          description="Få kontroll över ditt uppdrag genom att spara alla händelser och körningar i din dagbok."
          action={
            <Button
              title="Skapa ett inlägg"
              variant="secondary"
              onClick={openModal}
              disabled={!principalId}
            />
          }
        />
      </div>
    )}
  </div>
  <Modal visible={isOpen} onClose={() => setEditJournalEntryId(null)}>
    <CreateJournalEntry
      onCancel={closeModal}
      onSuccess={closeModal}
      journalEntryId={editJournalEntryId}
    />
  </Modal>
</LoadingContainer>


      <Modal visible={isOpen} onClose={() => setEditJournalEntryId(null)}>
        <CreateJournalEntry
          onCancel={closeModal}
          onSuccess={closeModal}
          journalEntryId={editJournalEntryId}
        />
      </Modal>
    </Fragment>
  )
}

export default Journal
